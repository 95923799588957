<template>
    <!-- 新闻讯息和活动信息列表 -->
    <div class="main_box">
        <ul class="listUl">
            <li @click="handelGo(item.id)" v-for="(item, index) in list" :key="index">
                <span class="name">
                    {{ item.title }}
                </span>
                <span class="time">{{ parseTime(item.createdAt) }}</span>
            </li>
        </ul>
        <div class="pageBtnCon">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="page" background :page-size="limit"
                layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>
  
<script>
import { APPID, NEWS, ACTIVE } from '@/utils/api/config'
import { parseTime } from "@/utils/tools"
export default {
    data() {
        return {
            page: 1,
            limit: 12,
            total: 0,
            list: [],
        };
    },
    methods: {
        handelGo(id) {
            // this.$router.push({ path: "/homeDetail/" + id });
            let { href } = this.$router.resolve({ path: "/homeDetail/" + id })
            window.open(href, '_blank')
        },
        getList() {
            var key = NEWS
            if (this.$route.name === 'ActiveList') {
                key = ACTIVE
            }
            this.axios.get(`api/fusion/pc/news/list?appId=${APPID}&channelId=${key}&page=${this.page}&limit=${this.limit}`, {}).then(res => {
                if (res.code !== '200') {
                    this.$message.error(res.msg)
                    return
                }
                this.list = res.data.list || []
                this.total = res.data.count

            })
        },
        handleCurrentChange(val) {
            this.page = val
            this.getList()
        },

    },
    created() {
        this.getList()
    },
};
</script>
  
<style lang="scss" scoped>
.main_box {
    margin-top: 50px;
    padding-bottom: 80px;
    // min-height: 820px;
}

.listUl {
    // min-height: 684px;
}

ul {
    margin: 1em 0;

    li {
        font-size: 18px;
        height: 55px;
        line-height: 55px;
        position: relative;
        cursor: pointer;

        .name {
            display: inline-block;
            width: 660px;
            margin-left: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .time {
            font-size: 14px;
            color: #bfbfbf;
            float: right;
        }
    }

    li::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 25px;
        width: 5px;
        height: 5px;
        background-color: #bfbfbf;
        /* 设置圆点的颜色 */
    }
}

.pageBtnCon {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>